<template>
  <div>
    <main class="virt-grid-row pa-3" :style="source.confirmed ? 'background-color: whitesmoke' : 'background-color: white'">
      <v-btn v-if="computedClockIns.some(ci => ci.id > 0 && !ci.confirmed)"
             @click="confirmAllClockInPairs"
             :disabled="allClockInsAreConfirmed || source.disabled"
             x-small fab width="25px" height="25px" depressed
             style="position: absolute; left: 180px; top: 15px">
        <v-icon size="13px">{{ icons.mdiCheck }}</v-icon>
      </v-btn>

      <div style="position: absolute; right: 3px; top: 50%; transform: translateY(-50%);" class="px-3">
        <v-btn v-if="true" @click="openNewClockInHandler" x-small fab width="25px" height="25px" depressed>
          <v-icon size="13px">{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </div>

      <!-- Employee name -->
      <section class="wb-w100 f-12 pa-1 d-flex justify-space-between">
        <b>{{ dataSource.name }}</b>
      </section>

      <section>
        <!-- no clockin with default project -->
        <section v-if="dataSource.clockIns.length <= 0">
          <div class="report-row">
            <p v-if="defaultProject" class="ma-0 pa-0 d-flex align-center wb-w100 f-12">
              {{ defaultProject }}
            </p>
          </div>
        </section>

        <template v-else>
          <section v-for="(clockIn, i) in computedClockIns" :key="`type_${clockIn.id}-${i}`"
                   @click="startEditingClockIn($event, clockIn)"
                   class="mb-2 clockin-row"
                   :style="[
                       rowColor(clockIn),
                       { gridTemplateColumns: `50px ${middleColumnWidth} 120px` }
                     ]">
            <!-- Confirmed -->
            <div class="justify-center d-flex" style="margin-top:-6px">
              <v-checkbox v-if="clockIn.id > 0 && !clockIn.subRow" v-model="clockIn.confirmed" color="main" dense :hide-details="true" @change="value => confirmClockInPair(clockIn, value)"></v-checkbox>
              <v-icon dense v-else-if="clockIn.confirmed">{{ icons.mdiCheck }}</v-icon>
            </div>

            <div class="clockin-mid-grid-row" :style="{ maxWidth: middleColumnWidth, gridTemplateColumns: middleContainerTemplateColumns }">

              <!-- Sector -->
              <div class="report-row">
                <v-row class="ma-0 pa-0" justify="space-between">
                  <p v-if="!clockIn.edit" :key="forceUpdateLabel" class="ma-0 pa-0 d-flex align-center f-12">
                    {{ getSectorKeyCodeFromClockIn(clockIn) }}
                  </p>
  
                  <v-menu v-else-if="!clockIn.project"
                          v-model="clockIn.openMenu"
                          :value="clockIn.openMenu" :key="forceUpdate"
                          :disabled="clockIn.confirmed || source.disabled"
                          offset-x allow-overflow max-height="400px"
                          :open-on-focus="true" :open-on-click="false"
                          :close-on-click="false" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        @click.stop="clockIn.openMenu = true"
                        :disabled="clockIn.confirmed || source.disabled"
                        height="13px"
                        class="f-12"
                        style="width: 90px;"
                        outlined
                        hide-details
                        autocomplete="off"
                        v-model="clockIn.search"
                        @blur="textBlur(clockIn)"
                        @input="value => inputHandler(value)">
                      </v-text-field>
                    </template>
  
                    <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
                      <v-list dense>
                        <template v-if="rowSectorOptions.list.length">
                          <v-list-item v-for="(sector,i) in rowSectorOptions.list" :key="i" @click="changeSector(clockIn, sector)">
                            <v-list-item-content>
                              <v-list-item-title>{{ sector.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ sector.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!rowSectorOptions.loading && rowSectorOptions.hasNext">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                        </template>
  
                        <template v-else>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
  
                  <!-- add InertClockInItems dialog -->
                  <div class="d-flex justify-center align-center pr-1" v-if="!clockIn.project">
                    <template v-if="!clockIn.subRow">
                      <v-btn x-small :disabled="clockIn.confirmed || source.disabled" icon color="main" @click="openInertClockInItemDialog(clockIn)">
                        <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </v-row>

                <v-row class="ma-0 pa-0">
                  <p class="ma-0 pa-0 f-12 wb-w100 grey--text" style="text-align: start;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                    {{ clockIn.sector ? clockIn.sector.description : "-" }}
                  </p>
                </v-row>
              </div>

              <!-- Activity -->
              <div class="report-row">
                <v-row class="ma-0 pa-0" justify="space-between">
                  <p v-if="!clockIn.edit" :key="forceUpdateLabel" class="ma-0 pa-0 d-flex align-center f-12">
                    {{ getActivityKeyCodeFromClockIn(clockIn) }}
                  </p>

                  <v-menu v-else-if="!clockIn.project"
                          v-model="clockIn.openMenuActivity"
                          :value="clockIn.openMenuActivity" :key="forceUpdate"
                          :disabled="clockIn.confirmed || source.disabled"
                          offset-x allow-overflow max-height="400px"
                          :open-on-focus="true" :open-on-click="false"
                          :close-on-click="false" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        @click.stop="clockIn.openMenuActivity = true"
                        :disabled="clockIn.confirmed || source.disabled"
                        height="13px"
                        class="f-12"
                        style="width: 90px;"
                        outlined
                        hide-details
                        autocomplete="off"
                        v-model="clockIn.searchActivity"
                        @blur="textBlurActivities(clockIn)"
                        @input="value => inputActivitytHandler(value)">
                      </v-text-field>
                    </template>

                    <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
                      <v-list dense>
                        <template v-if="rowActivityOptions.list.length">
                          <v-list-item v-for="(activity,i) in rowActivityOptions.list" :key="i" @click="changeActivity(clockIn, activity)">
                            <v-list-item-content>
                              <v-list-item-title>{{ activity.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ activity.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!rowActivityOptions.loading && rowActivityOptions.hasNext">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onActivityIntersect" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                        </template>

                        <template v-else>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <p class="ma-0 pa-0 f-12 wb-w100 grey--text" style="text-align: start;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                    {{ clockIn.activity ? clockIn.activity.description : "-" }}
                  </p>
                </v-row>

              </div>

              <!-- Project -->
              <div class="report-row">
                <v-row class="ma-0 pa-0" justify="space-between">
                  <p v-if="!clockIn.edit" :key="forceUpdateLabel" class="ma-0 pa-0 d-flex align-center f-12">
                    {{ getProjectKeyCodeFromClockIn(clockIn) }}
                  </p>

                  <v-menu v-else-if="!clockIn.sector"
                          v-model="clockIn.openMenu"
                          :value="clockIn.openMenu" :key="forceUpdate"
                          :disabled="clockIn.confirmed || source.disabled"
                          offset-x allow-overflow max-height="400px"
                          :open-on-focus="true" :open-on-click="false"
                          :close-on-click="false" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        @click.stop="clockIn.openMenu = true"
                        :disabled="clockIn.confirmed || source.disabled"
                        height="13px"
                        class="f-12"
                        style="width: 90px;"
                        outlined
                        hide-details
                        autocomplete="off"
                        v-model="clockIn.searchProject"
                        @blur="textBlurProjects(clockIn)"
                        @input="value => inputProjectHandler(value)">
                      </v-text-field>
                    </template>

                    <div style="max-height: 300px; z-index: 2; background-color: #fff" class="scroll-bar">
                      <v-list dense>
                        <template v-if="rowProjectsOptions.list.length > 0">
                          <v-list-item v-for="(project,i) in rowProjectsOptions.list" :key="i" @click="changeProject(clockIn, project)">
                            <v-list-item-content>
                              <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                              <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="!rowProjectsOptions.loading && rowProjectsOptions.hasNext">
                            <div class="d-flex align-center justify-center" style="width: 100%">
                              <v-progress-circular v-intersect.quiet="onProjectIntersect" indeterminate color="main" size="24"></v-progress-circular>
                            </div>
                          </v-list-item>
                        </template>

                        <template v-else>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </div>
                  </v-menu>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <p class="ma-0 pa-0 f-12 wb-w100 grey--text" style="text-align: start;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                    {{ clockIn.project ? clockIn.project.label : "-" }}
                  </p>
                </v-row>
              </div>

              <!-- Part -->
              <div class="report-row">
                <v-row class="ma-0 pa-0">
                  <p v-if="!clockIn.edit" class="ma-0 pa-0" style="font-size: 12px; word-wrap: break-word">
                    {{ projectPartValue(clockIn) }}
                  </p>

                  <v-select v-else-if="projectParts(clockIn)"
                            v-model="clockIn.part"
                            :disabled="clockIn.confirmed || source.disabled"
                            :items="projectParts(clockIn)"
                            :no-data-text="translations.noPartsAvailable"
                            append-icon=""
                            class="part-select"
                            clearable
                            dense
                            height="15px"
                            hide-details
                            item-text="keyCode"
                            item-value="id"
                            outlined
                            return-object
                            style="font-size: 12px; width: 90px"
                            @click:clear="clockIn.part = null">
                    <template v-slot:item="data">
                      <small>{{ `${data.item.keyCode} - ${data.item.description}` }}</small>
                    </template>
                  </v-select>
                </v-row>

                <v-row class="ma-0 pa-0">
                  <p class="ma-0 pa-0 f-12 wb-w100 grey--text" style="text-align: start;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                    {{ clockIn.part ? clockIn.part.description : "-" }}
                  </p>
                </v-row>
              </div>

              <!-- Clock IN -->
              <div class="report-row d-flex align-center" style="height: 32px; display: flex; justify-content: center">
                <div v-if="!clockIn.subRow" style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockIn" label small :color="`green lighten-5`">
                      <span :class="`green--text`" class="mr-1">
                        {{ formatHHmm(clockIn.clockIn) }}
                      </span>
                    </v-chip>
                  </template>

                  <v-text-field v-else v-model="clockIn.clockInTimeStamp"
                                v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                :disabled="clockIn.confirmed || source.disabled"
                                height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                  </v-text-field>
                </div>

                <!-- Location (Clock In) -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="clockIn.geolocation && clockIn.geolocation.inCoordinates && !clockIn.subRow" x-small icon color="main" class="mapLink" @click="displayMap(clockIn.sector, clockIn.geolocation, 'IN')">
                    <v-icon class="mapLink" style="filter: hue-rotate(240deg);">mdi-map-marker</v-icon>
                  </v-btn>
                </div>
              </div>

              <!-- Clock OUT -->
              <div class="report-row d-flex align-center" style="height: 32px; display: flex; justify-content: center">
                <div v-if="!clockIn.subRow" style="width: 60px">
                  <template v-if="!clockIn.edit">
                    <v-chip v-if="clockIn.clockOut" label small :color="`red lighten-5`">
                      <span :class="`red--text`" class="mr-1">
                        {{ formatHHmm(clockIn.clockOut) }}
                      </span>
                    </v-chip>
                  </template>

                  <template v-if="clockIn.edit">
                    <v-text-field v-model="clockIn.clockOutTimeStamp"
                                  v-mask="'##:##'" :rules="[rules.isTimeFormat]"
                                  :disabled="clockIn.confirmed || source.disabled"
                                  height="13px" style="width: 60px; max-width: 60px; font-size:12px" outlined hide-details>
                    </v-text-field>
                  </template>
                </div>

                <!-- Location (Clock Out) -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <v-btn v-if="clockIn.geolocation && clockIn.geolocation.outCoordinates && !clockIn.subRow" x-small icon color="main" class="mapLink" @click="displayMap(clockIn.sector, clockIn.geolocation, 'OUT')">
                    <v-icon class="mapLink" style="filter: hue-rotate(140deg);">mdi-map-marker</v-icon>
                  </v-btn>
                </div>

                <!-- delete ClockOut button -->
                <div class="d-flex justify-center align-center" style="width: 20px">
                  <template v-if="clockIn.edit && !clockIn.subRow">
                    <v-btn v-if="clockIn.clockOutTimeStamp" x-small :disabled="clockIn.confirmed || source.disabled" icon color="red" @click="deleteClockOutFromClockInPair(clockIn)">
                      <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                    </v-btn>
                  </template>
                </div>
              </div>

              <!-- Minutes Worked -->
              <div class="justify-center d-flex report-row">
                  <span v-if="!clockIn.subRow" class="pa-0 ma-0" style="font-size: 12px;">
                    {{ clockIn.hour }}
                  </span>
              </div>

              <!-- Work Hours -->
              <div class="justify-center d-flex report-row">
                <template v-if="i == 0">
                  <span class="pa-0 ma-0" style="font-size: 12px;">
                    {{ sourceCopy.workHours }}
                  </span>
                </template>
              </div>

              <!-- Lunch Break -->
              <div v-if="possibleQuestions.includes(4)" class="justify-center d-flex report-row">
                <div v-if="!clockIn.subRow">
                  <template v-if="!clockIn.edit">
                      <span class="pa-0 ma-0" style="font-size: 12px;">
                        {{ clockIn.lunchTime || clockIn.lunchTime === 0 ? getLunchText(clockIn.lunchTime) : '' }}
                      </span>
                  </template>
  
                  <template v-else>
                    <v-select v-if="(clockIn.lunchTime !== null && clockIn.lunchTime >= 0) || !hasLunchTime"
                              v-model="clockIn.lunchTime"
                              :disabled="clockIn.confirmed || source.disabled"
                              :items="lunchBreakOptions"
                              style="font-size: 12px; width: 80px; max-width: 90px" outlined hide-details append-icon="" dense height="15px">
                    </v-select>
                  </template>
                </div>
              </div>

              <!-- Activity Hours -->
              <div class="report-row">

                <p v-if="!clockIn.edit"
                   class="pa-0 ma-0 f-12"
                   style="word-wrap: break-word; text-align: center">
                  {{ clockIn.activityHours }}
                </p>
                <v-text-field v-else-if="!clockIn.project"
                              v-model.number="clockIn.activityHours"
                              :disabled="clockIn.confirmed || source.disabled"
                              height="13px"
                              hide-details
                              outlined
                              style="width: 50px; font-size:12px;"></v-text-field>
              </div>

              <!-- Activity Notes -->
              <div class="report-row">
                <v-tooltip v-if="!clockIn.edit" :disabled="!clockIn.activityNotes" bottom color="black" max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <p v-on="on" v-bind="attrs" class="pa-0 ma-0" style="font-size: 10px; line-height: 16px; text-align: center; max-height: 30px;">
                      {{ clockIn.activityNotes }}
                    </p>
                  </template>
                  <span>{{ clockIn.activityNotes }}</span>
                </v-tooltip>

                <v-textarea v-else-if="!clockIn.project" v-model.trim="clockIn.activityNotes" rows="1" class="ma-0 pa-0 clock-in-notes" outlined hide-details :disabled="clockIn.confirmed || source.disabled"></v-textarea>
              </div>

              <!-- Equipment -->
              <div v-if="possibleQuestions.includes(21)" class="justify-center d-flex report-row">
                <v-btn :disabled="clockIn.confirmed || source.disabled"
                       outlined
                       x-small
                       @click="() => openEquipmentDialog(clockIn)">
                  {{ getEquipmentTypeText(clockIn) }}
                </v-btn>
              </div>

              <!-- Notes -->
              <div class="report-row">
                <div v-if="!clockIn.subRow">
                  <v-tooltip v-if="!clockIn.edit" :disabled="!clockIn.notes" bottom color="black" max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <p v-on="on" v-bind="attrs" class="pa-0 ma-0" style="font-size: 10px; line-height: 16px; text-align: center; max-height: 30px;">
                        {{ clockIn.notes }}
                      </p>
                    </template>
                    <span>{{ clockIn.notes }}</span>
                  </v-tooltip>

                  <v-textarea v-else v-model.trim="clockIn.notes" rows="1" class="ma-0 pa-0 clock-in-notes" outlined hide-details :disabled="clockIn.confirmed || source.disabled"></v-textarea>
                </div>
              </div>
            </div>

            <!-- Actions -->
            <div class="px-3 center">
              <div v-if="!clockIn.loading && !source.disabled">
                <div v-if="clockIn.edit">
                  <v-btn x-small icon class="mr-2" @click="!clockIn.subRow ? saveRow(clockIn) : saveInertClockInItem(clockIn)" :disabled="isSaveRowButtonDisabled(clockIn)">
                    <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                  </v-btn>

                  <v-btn x-small icon class="mr-2" @click.stop="onCancel(clockIn)">
                    <v-icon>{{ icons.mdiCancel }}</v-icon>
                  </v-btn>

                  <v-btn v-if="clockIn.id > 0" x-small icon @click="!clockIn.subRow ? deleteClockInPair(clockIn) : deleteInertClockInItem(clockIn)" color="red">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </div>

                <v-btn v-else-if="!clockIn.confirmed" x-small icon @click="startEditingClockIn($event, clockIn)">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                </v-btn>
              </div>
              <v-btn v-else-if="clockIn.loading" icon :loading="true"></v-btn>
            </div>
          </section>
        </template>
      </section>
    </main>
    <v-divider></v-divider>

    <!-- Map Dialog -->
    <clock-in-map :clockInMap="clockInMap"
                  @close="clockInMap.showDialog = false" />

    <!-- New Inert ClockIn Dialog -->
    <new-inert-clock-in-clock-out-dialog v-if="clockInDialog"
                                         :isOpen="clockInDialog"
                                         :employeeName="sourceCopy.name"
                                         :sector="rowDialogSectorOptions"
                                         :activity="rowDialogActivityOptions"
                                         :rowDialogProjectOptions="rowDialogProjectOptions"
                                         :newClockInLoader="newClockInLoader"
                                         :possibleQuestions="possibleQuestions"
                                         @onSubmit="saveNewClockInClockOut"
                                         @fetch-more="value => $emit('fetch-more', value)"
                                         @fetch-more-projects="value => $emit('fetch-more-projects', value)"
                                         @refetch="value => $emit('refetch', value)"
                                         @clickOutside="closeHandler"
                                         @refetch-all="$emit('refetch-all')"
                                         @set-project-list-filter="value => $emit('set-project-list-filter', value)">
    </new-inert-clock-in-clock-out-dialog>

    <!-- Add InertClockInItem Dialog -->
    <v-dialog v-if="selectedInertClockIn && inertClockInItemsDialog" :value="inertClockInItemsDialog" max-width="430px" @click:outside="closeAddClockInItemMenuHandler">
      <v-card class="pa-2">
        <v-card-title>
          <p class="ma-0" style="font-size: 1.25rem;">{{ translations.addSectorDialog }}</p>
        </v-card-title>
        <v-card-text>
          <!-- InertClockInItems -->
          <div v-for="(item, index) in newInertClockInItems" :key="index">
            <inert-clock-in-item
              :inertClockInItem="item"
              :index="index"
              :sectorOptions="rowDialogSectorOptions"
              :activityOptions="rowDialogActivityOptions"
              :isLast="isLast(index)"
              @fetch-more="value => $emit('fetch-more', value)"
              @refetch="value => $emit('refetch', value)"
              @add-item="addEmptyInertClockInItem()"
              @remove-item="value => removeInertClockInItem(value)"
            >
            </inert-clock-in-item>
          </div>
          <div class="mt-10 d-flex justify-end">
            <v-btn depressed color="main" :loading="savingItemsLoader" :dark="!disableSaveInertClockInItemsButton" :disabled="disableSaveInertClockInItemsButton" @click="saveInertClockInItems()">{{ translations.confirm }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Equipment Dialog -->
    <v-dialog v-if="equipmentDialog"
              v-model="equipmentDialog"
              max-width="650px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ translations.equipments }}</span>
          <v-btn @click="addEquipment()" color="main" dark depressed fab x-small>
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="mb-3">
            <v-col cols="8">{{ translations.equipmentCode }}</v-col>
            <v-col cols="3">{{ translations.hours }}</v-col>
            <v-col cols="1"></v-col>
          </v-row>

          <v-col v-for="(item, index) in selectedInertClockIn.vehicles" :key="index" class="pa-0">
            <v-divider class="my-2"></v-divider>

            <v-row no-gutters>
              <v-col cols="8" class="report-row">
                <smart-autocomplete-vehicles :key="`vehicle-smart-autocomplete-${index}`"
                                             :applyDateFilter="true"
                                             :dateStr="selectedDate"
                                             :keyIndex="index"
                                             :vehicle="item.vehicle"
                                             @set-value="vehicle => setClockInVehicle(vehicle, index)">
                </smart-autocomplete-vehicles>
              </v-col>
              <v-col cols="3" class="report-row">
                <v-text-field v-model="item.hours"
                              :rules="[rules.isGreaterThanZero]"
                              height="13px"
                              hide-details
                              min="0"
                              outlined
                              step="0.5"
                              style="width: 100px; font-size:12px"
                              type="number"></v-text-field>
              </v-col>
              <v-col cols="1">
                <div>
                  <v-btn color="red"
                         icon
                         x-small
                         @click="deleteEquipment(index)">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {
  mdiCancel,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiContentSaveOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiWeatherNight,
  mdiPencilCircleOutline,
  mdiPlusCircleOutline
} from '@mdi/js'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { translations } from '@/utils/common'
import { mapState } from 'vuex'
import { getLunchBreak, getLunchTimes, getResetAllowanceOnWeekend, getShowClockInTimeAlert, getInertSectorKeyCode } from '@/utils/api/config'

import NewInertClockInClockOutDialog from './NewInertClockInClockOutDialog.vue'
import InertClockInItem from '@/components/InertClockInItem.vue'
import ClockInMap from '@/components/ClockInMap.vue'

import updateOneInertClockInMutation from '@/graphql/UpdateOneInertClockIn.gql'
import deleteInertClockIn from '@/graphql/DeleteInertClockIn.gql'
import deleteInertClockOut from '@/graphql/DeleteInertClockOut.gql'
import saveInertClockInAdminRequestMutation from '@/graphql/SaveInertClockInAdminRequest.gql'
import updateInertWorkHours from '@/graphql/InertWorkHours.gql'
import saveInertClockInItemMutation from '@/graphql/SaveInertClockInItem.gql'
import deleteInertClockInItemMutation from '@/graphql/DeleteInertClockInItem.gql'
import saveInertClockInItemsMutation from '@/graphql/SaveInertClockInItems.gql'

const SmartAutocompleteVehicles = () => import ('@/components/SmartAutocompleteVehicles')

export default {
  name: 'InertVirtualReportRow',

  components: {
    ClockInMap,
    NewInertClockInClockOutDialog,
    InertClockInItem,
    SmartAutocompleteVehicles
  },

  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default: () => ({})
    },
    possibleQuestions: {
      type: Array,
      default: () => []
    },
    middleColumnWidth: {
      type: String,
      required: true
    },
    middleContainerTemplateColumns: {
      type: String,
      required: true
    },
    rowDialogSectorOptions: {
      type: Object,
      default: () => {
      }
    },
    rowProjectsOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogActivityOptions: {
      type: Object,
      default: () => {
      }
    },
    selectedDate: {
      type: String,
      default: ''
    },
    confirmTypeBasedOnCapabilities: {
      type: Number,
      default: 1
    },
    possibleActivities: {
      type: Array,
      default: () => []
    },
    rowSectorOptions: {
      type: Object,
      default: () => {
      }
    },
    rowDialogProjectOptions: {
      type: Object,
      default: () => {
      }
    },
    rowActivityOptions: {
      type: Object,
      default: () => {
      }
    },
  },

  created() {
    // A copy of the original data which is modified
    this.dataSource = cloneDeep(this.source)
    // The original data, used as source to restore dataSource to an unmodified state
    this.sourceCopy = cloneDeep(this.source)
    this.lunchBreak = getLunchBreak()
    this.totalActivityHours = this.getTotalActivityHours()
    this.workHoursWithoutProjects = this.getTotalWorkHoursWithoutProjects()
  },

  mixins: [MomentMixin],

  data: () => ({
    clockInMap: {
      showDialog: false,
      sectorName: null,
      employeeName: null,
      employeeInLocation: null,
      employeeOutLocation: null,
      clickOn: null
    },
    dataSource: {},
    sourceCopy: {},
    forceUpdate: 0,
    forceUpdateLabel: 0,
    selectedClockInPair: {},
    clockInDialog: false,
    vanDialog: false,
    refuelDialog: false,
    securityDialog: false,
    newClockInLoader: false,
    maintenanceDialog: false,
    disableNewCreationBtn: false,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isGreaterThanZero: value => {
        return value > 0
      },
      isNotBlank: value => {
        return value && value.trim() != ''
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    equipmentDialog: false,
    order: null,
    switchHourDialog: false,
    dialogType: '',
    dialogTitle: '',
    dialogTypeHours: '',
    lunchBreak: {},
    inertClockInItemsDialog: false,
    selectedInertClockIn: null,
    newInertClockInItems: [],
    savingItemsLoader: false,
    totalActivityHours: 0,
    workHoursWithoutProjects: 0
  }),

  watch: {
    source: {
      deep: true,
      handler(val) {
        this.dataSource = cloneDeep(val)
        this.sourceCopy = cloneDeep(this.source)
      }
    }
  },

  methods: {
    formatHHmm(timestamp) {
      return timestamp ? this.momentDate(timestamp, 'HH:mm') : null
    },

    textBlur(clockIn) {
      setTimeout(() => {
        clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn)
        clockIn.openMenu = false
        this.$emit('set-sector-list-filter', { value: '', property: 'rowSectorOptions' })
      }, 400)
    },

    textBlurProjects(clockIn) {
      setTimeout(() => {
        clockIn.searchProject = this.getProjectKeyCodeFromClockIn(clockIn)
        clockIn.openMenu = false
        this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOptions' })
      }, 400)
    },

    textBlurActivities(clockIn) {
      setTimeout(() => {
        clockIn.searchActivity = this.getActivityKeyCodeFromClockIn(clockIn)
        clockIn.openMenuActivity = false
        this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOptions' })
      }, 400)
    },

    getLunchText(num) {
      const foundOption = this.lunchBreakOptions.find(lunch => lunch.value === num)

      return foundOption.text || ''
    },

    async deleteClockInPair(clockInPair) {
      clockInPair.loading = true

      await this.$apollo.mutate({
        mutation: deleteInertClockIn,
        variables: { clockInId: clockInPair.id }
      })

      this.$emit('clockin-pair-deleted', this.source.name, clockInPair)
      await this.updateWorkHours()

      clockInPair.loading = false
      clockInPair.edit = false
    },

    getActivityKeyCodeFromClockIn(clockIn) {
      return clockIn?.activity?.keyCode || '-'
    },

    changeSector(clockIn, value) {
      clockIn.sector = value?.sector ? value.sector : value
      clockIn.search = value.keyCode
      clockIn.openMenu = false
    },

    changeProject(clockIn, value) {
      clockIn.project = value?.project ? value.project : value
      clockIn.searchProject = value.keyCode
      clockIn.openMenu = false
      clockIn.part = null
    },

    changeActivity(clockIn, value) {
      clockIn.activity = value?.activity ? value.activity : value
      clockIn.searchActivity = value.keyCode
      clockIn.openMenuActivity = false
    },

    getSectorKeyCodeFromClockIn(clockIn) {
      if (clockIn?.sector?.keyCode) return clockIn.sector.keyCode
      else return '-'
    },

    onCancel(clockIn) {
      this.dataSource = cloneDeep(this.sourceCopy)
      clockIn.edit = false
    },

    isSaveRowButtonDisabled(clockIn) {
      if(clockIn.project) return false

      const missingSector = !clockIn?.sector?.keyCode ? true : false

      if (clockIn.subRow) {
        if (missingSector) return true
        return false
      }

      const isLunchTimeMissing = clockIn?.lunchTime === null
      if (isLunchTimeMissing && this.possibleQuestions.includes(4)) return true

      if (!clockIn.clockInTimeStamp || !this.rules.isTimeFormat(clockIn.clockInTimeStamp) || (clockIn.clockOutTimeStamp && !this.rules.isTimeFormat(clockIn.clockOutTimeStamp))) return true

      if (missingSector) return true

      const clockInHours = Number(clockIn.clockInTimeData.HH)
      const lateShift = clockInHours >= 18

      // if clockIn is larger than 18 return false to prevent any issues on the out
      if (lateShift) return false

      if (clockIn.clockOutTimeData) {
        const clockOutHours = Number(clockIn.clockOutTimeData.HH)
        const clockInMinutes = Number(clockIn.clockInTimeData.mm)
        const clockOutMinutes = Number(clockIn.clockOutTimeData.mm)
  
        if (clockInHours === clockOutHours) {
          return clockOutMinutes < clockInMinutes
        }
      }

      if (clockIn.confirmed) {
        return true
      }

      return false
    },

    async saveRow(row) {
      try {
        row.loading = true

        //SAVE/UPDATE IN - OUT
        row.clockInTimeData.HH = row.clockInTimeStamp[0] + row.clockInTimeStamp[1]
        row.clockInTimeData.mm = row.clockInTimeStamp[3] + row.clockInTimeStamp[4]
        //const clockInTime = Number(row.clockInTimeData.HH)

        const timeStampClockIn = moment.utc(row.clockIn ?? this.source.date)
        timeStampClockIn.set('hour', Number(row.clockInTimeData.HH))
        timeStampClockIn.set('minute', Number(row.clockInTimeData.mm))

        row.clockOutTimeData.HH = row.clockOutTimeStamp ? row.clockOutTimeStamp[0] + row.clockOutTimeStamp[1] : ""
        row.clockOutTimeData.mm = row.clockOutTimeStamp ? row.clockOutTimeStamp[3] + row.clockOutTimeStamp[4] : ""

        const timeStampClockOut = moment.utc(row.clockOut ?? this.source.date)

        if (row.clockOutTimeStamp) {
          timeStampClockOut.set('hour', Number(row.clockOutTimeData.HH))
          timeStampClockOut.set('minute', Number(row.clockOutTimeData.mm))
        }

        const clockInUpdateRequestObject = {
          id: row.id,
          clockInUpdatedTimestampStr: this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm'),
          clockOutUpdatedTimestampStr: row.clockOutTimeStamp ? this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm') : null,
          confirmed: !row?.confirmed ? this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED : this.confirmTypeBasedOnCapabilities,
          notes: row.notes,
          lunchTime: row.lunchTime
        }

        const res = await this.$apollo
          .mutate({
            mutation: updateOneInertClockInMutation,
            variables: {
              inertClockInUpdateRequestObject: clockInUpdateRequestObject
            }
          })
          .catch(error => {
            this.handleError(error)
          })

        row.hour = res.data.updateOneInertClockIn.hour
        row.clockIn = this.momentDate(timeStampClockIn, 'YYYY-MM-DD HH:mm')
        row.clockOut = row.clockOutTimeStamp ? this.momentDate(timeStampClockOut, 'YYYY-MM-DD HH:mm') : null

        // save InertClockInItem
        await this.saveInertClockInItem(row)

        row.edit = false
        this.forceUpdateLabel++
        row.loading = false

        this.$emit('clockin-pair-updated', this.source.name, row)
        await this.updateWorkHours()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        row.loading = false
        this.showSuccess = false
        this.showError = true
      }
    },

    async saveInertClockInItem(row) {
      try {
        row.loading = true

        let vehicles = []
        if(row.vehicles && row.vehicles.length) {
          vehicles = row.vehicles
          for(let i=0; i<vehicles.length; i++){
            delete vehicles[i].__typename
            delete vehicles[i].vehicle.__typename
          }
        }

        const requestObject = {
          id: row.inertClockInItemId,
          inertClockInId: row.id,
          sector: row.sector ? { id: row.sector.id } : null,
          activity: row.activity ? { id: row.activity.id } : null,
          hours: row.activityHours,
          activityNotes: row.activityNotes,
          vehicles: vehicles,
          project: row.project ? { id: row.project.id } : null,
          part: row.part ? { id: row.part.id } : null
        }

        await this.$apollo
          .mutate({
            mutation: saveInertClockInItemMutation,
            variables: {
              item: requestObject
            }
          })
          .catch(error => {
            this.handleError(error)
          })
  
        row.loading = false
        row.edit = false
        this.$emit('inert-clockIn-item-updated', this.dataSource.id, row)
        await this.updateWorkHours()
      } catch (error) {
        console.log(error)
        row.loading = false
        this.showSuccess = false
        this.showError = true
      }
    },

    async deleteInertClockInItem(row) {
      try {
        row.loading = true
        await this.$apollo
        .mutate({
          mutation: deleteInertClockInItemMutation,
          variables: {
            itemId: row.inertClockInItemId
          }
        })
        .catch(error => {
          this.handleError(error)
        })
        row.loading = false
        this.$emit('inert-clockIn-item-deleted', this.dataSource.id, row.inertClockInItemId)
        await this.updateWorkHours()
      } catch (error) {
        console.log(error)
        row.loading = false
        this.showSuccess = false
        this.showError = true
      }
    },

    async confirmClockInPair(clockInPair, value) {
      clockInPair.confirmed = value
      await this.saveRow(clockInPair)
    },

    async confirmAllClockInPairs() {
      for (let ci of this.computedClockIns) {
        if (ci.id > 0 && !ci.subRow) {
          await this.confirmClockInPair(ci, true)
        }
      }
    },

    openNewClockInHandler() {
      this.clockInDialog = true
    },

    startEditingClockIn(e, clockIn) {
      if (!clockIn.confirmed) {
        clockIn.edit = true
        clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn)
        clockIn.searchProject = this.getProjectKeyCodeFromClockIn(clockIn)
        clockIn.searchActivity = this.getActivityKeyCodeFromClockIn(clockIn)

        if (clockIn.openMenu) {
          this.closeMenuHandler(clockIn)
        }
      }
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-sectors', 'rowSectorOptions')
      }
    },

    onProjectIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-projects', 'rowProjectsOptions')
      }
    },

    onActivityIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-activities', 'rowActivityOptions')
      }
    },

    inputHandler(value) {
      this.$emit('set-sector-list-filter', { value, property: 'rowSectorOptions' })
    },

    inputProjectHandler(value) {
      this.$emit('set-project-list-filter', { value, property: 'rowProjectsOptions' })
    },

    inputActivitytHandler(value) {
      this.$emit('set-activity-list-filter', { value, property: 'rowActivityOptions' })
    },

    closeMenuHandler(clockIn) {
      clockIn.search = this.getSectorKeyCodeFromClockIn(clockIn.clockIn)
      clockIn.searchProject = this.getProjectKeyCodeFromClockIn(clockIn.clockIn)
      clockIn.searchActivity = this.getActivityKeyCodeFromClockIn(clockIn.clockIn)
      clockIn.openMenu = false
      this.$emit('set-sector-list-filter', { value: '', property: 'rowSectorOptions' })
      this.$emit('set-project-list-filter', { value: '', property: 'rowProjectsOptions' })
    },

    formatEmployeeName(name) {
      let fullname = name.split(' ')
      return fullname[0] + ' ' + fullname[1].substring(0, 3) + '.'
    },

    //dialog callback
    async saveNewClockInClockOut(clockInInfo) {
      this.newClockInLoader = true

      const newClockInRequest = clockInInfo.newClockInRequest

      const clockInTimeHH = Number(newClockInRequest.clockInTimestamp[0] + newClockInRequest.clockInTimestamp[1])
      const clockInTimemm = Number(newClockInRequest.clockInTimestamp[3] + newClockInRequest.clockInTimestamp[4])
      const clockOutTimeHH = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[0] + newClockInRequest.clockOutTimestamp[1]) : 0
      const clockOutTimemm = newClockInRequest.clockOutTimestamp ? Number(newClockInRequest.clockOutTimestamp[3] + newClockInRequest.clockOutTimestamp[4]) : 0

      const clockInTimestamp = moment.utc(this.source.date)
      clockInTimestamp.set('hour', Number(clockInTimeHH))
      clockInTimestamp.set('minute', Number(clockInTimemm))

      //add one day if clock out is happening the next day
      let dateOut = this.source.date
      if (clockOutTimeHH < clockInTimeHH) {
        dateOut = moment(this.source.date).add(1, 'days')
        dateOut = dateOut.format('YYYY-MM-DD')
      }

      const clockOutTimestamp = moment.utc(dateOut)
      clockOutTimestamp.set('hour', Number(clockOutTimeHH))
      clockOutTimestamp.set('minute', Number(clockOutTimemm))

      let employeeId = newClockInRequest.employee ? newClockInRequest.employee.id : this.source.id

      const clockInAndOutRequest = {
        employeeId: employeeId,
        clockIn: this.momentDate(clockInTimestamp, 'YYYY-MM-DD HH:mm'),
        clockOut: newClockInRequest.clockOutTimestamp ? this.momentDate(clockOutTimestamp, 'YYYY-MM-DD HH:mm') : null,
        lunchTime: newClockInRequest.lunchTime,
        confirmed: this.$store.getters.clockInConfirmedTypes.NOT_CONFIRMED,
        inertClockInItems: newClockInRequest.inertClockInItems
      }

      const resInAndOut = await this.$apollo.mutate({
        mutation: saveInertClockInAdminRequestMutation,
        variables: { inertClockInRequest: clockInAndOutRequest }
      })

      const newInertClockIn = resInAndOut.data.saveInertClockInAdminRequest

      newInertClockIn.loading = false
      newInertClockIn.edit = false
      newInertClockIn.openMenu = false
      newInertClockIn.clockInTimeStamp = moment.utc(newInertClockIn?.clockIn).format('HH:mm')
      newInertClockIn.clockOutTimeStamp = newInertClockIn.clockOut ? moment.utc(newInertClockIn?.clockOut).format('HH:mm') : ""
      newInertClockIn.clockInTimeData = {
        HH: moment.utc(newInertClockIn?.clockIn).format('HH'),
        mm: moment.utc(newInertClockIn?.clockIn).format('mm')
      }
      newInertClockIn.clockOutTimeData = {
        HH: newInertClockIn.clockOut ? moment.utc(newInertClockIn.clockOut).format('HH') : "",
        mm: newInertClockIn.clockOut ? moment.utc(newInertClockIn.clockOut).format('mm') : ""
      }
      newInertClockIn.subRow = false
      if (newInertClockIn.inertClockInItems?.length) {
        let firstItem = newInertClockIn.inertClockInItems[0]
        newInertClockIn.inertClockInItemId = firstItem.id
        newInertClockIn.sector = firstItem.sector
        newInertClockIn.activity = firstItem.activity
        newInertClockIn.activityHours = firstItem.hours
        newInertClockIn.activityNotes = firstItem.activityNotes
        newInertClockIn.project = firstItem.project
        newInertClockIn.part = firstItem.part
      }

      this.$emit('new-clockin-pair-saved', this.dataSource.name, newInertClockIn)
      await this.updateWorkHours()

      //clear dialog project filter
      this.$emit('set-sector-list-filter', { value: '', property: 'rowDialogSectorOptions' })

      this.newClockInLoader = false
      this.clockInDialog = false
    },

    async deleteClockOutFromClockInPair(clockInPair) {
      clockInPair.loading = true

      await this.$apollo
        .mutate({
          mutation: deleteInertClockOut,
          variables: { clockInId: clockInPair.id }
        })
        .catch(error => {
          this.handleError(error)
        })

      clockInPair.clockOut = null
      clockInPair.clockOutTimeStamp = null
      clockInPair.clockOutTimeData = {
        HH: '',
        mm: ''
      }
      clockInPair.hour = null
      clockInPair.geolocation ? clockInPair.geolocation.outCoordinates = null : null

      this.$emit('clockin-pair-updated', this.source.name, clockInPair)
      await this.updateWorkHours()

      clockInPair.loading = false
      clockInPair.edit = false
    },

    async updateWorkHours() {
      const date = this.sourceCopy.date
      const employeeId = this.sourceCopy.id
      const res = await this.$apollo.query({
        query: updateInertWorkHours,
        variables: {
          dateStr: date,
          employeeId: employeeId
        },
        fetchPolicy: 'network-only'
      })
      const inertWorkHours = res.data.updateInertWorkHours
      this.sourceCopy.workHours = inertWorkHours.workHours
      this.totalActivityHours = inertWorkHours.activityHours
      this.workHoursWithoutProjects = this.getTotalWorkHoursWithoutProjects()
    },

    closeHandler() {
      this.clockInDialog = false
      this.resetFilters()
    },

    closeAddClockInItemMenuHandler() {
      this.inertClockInItemsDialog = false
      this.selectedInertClockIn.edit = false
      this.selectedInertClockIn = null
      this.newInertClockInItems = []
      this.resetFilters()
    },

    resetFilters() {
      this.rowDialogSectorOptions.params.filter = null
      this.rowDialogActivityOptions.params.filter = null
      this.$emit('refetch-all')
    },

    openInertClockInItemDialog(inertClockIn) {
      this.inertClockInItemsDialog = true
      this.selectedInertClockIn = inertClockIn
      this.addEmptyInertClockInItem()
    },

    addEmptyInertClockInItem() {
      this.resetFilters()
      this.newInertClockInItems.push({
        sector: null,
        activity: null,
        hours: 0,
        activityNotes: null
      })
    },

    removeInertClockInItem(index) {
      this.newInertClockInItems.splice(index, 1)
    },

    isLast(index) {
      return index === this.newInertClockInItems.length - 1
    },

    async saveInertClockInItems() {
      this.savingItemsLoader = true

      try {
        const res = await this.$apollo
          .mutate({
            mutation: saveInertClockInItemsMutation,
            variables: {
              inertClockInId: this.selectedInertClockIn.id,
              items: this.newInertClockInItems
            }
          })
          .catch(error => {
            this.handleError(error)
          })

        let items = res.data.saveInertClockInItems
        this.$emit('inert-clockIn-items-added', this.dataSource.id, this.selectedInertClockIn, items)
        this.savingItemsLoader = false
        this.closeAddClockInItemMenuHandler()
        await this.updateWorkHours()
      } catch (error) {
        console.error(error)
      }
    },

    rowColor(row) {
      if(row.project) return {}
      let keyCode = this.getSectorKeyCodeFromClockIn(row)
      return !keyCode || keyCode === "-" || getInertSectorKeyCode().includes(keyCode) || !this.workHoursMatch()
        ? { 'background-color': '#ffcccc' } 
        : {}
    },

    getTotalActivityHours() {
      return this.dataSource.clockIns.reduce((a, b) => a + (b.activityHours), 0)
    },

    getTotalWorkHoursWithoutProjects() {
      return this.dataSource.clockIns.reduce((sum, clockIn) => {
        if (clockIn.sector !== null && !clockIn.subRow) {
          return sum + (clockIn.hour - clockIn.lunchTime)
        }
        return sum
      }, 0)
    },

    workHoursMatch() {
      return this.workHoursWithoutProjects === this.totalActivityHours * 60
    },

    displayMap(sector, geolocation, clickOn) {
      this.clockInMap = {
        showDialog: true,
        sectorName: sector?.description,
        employeeName: this.sourceCopy.name,
        employeeInLocation: geolocation.inCoordinates,
        employeeOutLocation: geolocation.outCoordinates,
        clickOn: clickOn
      }
    },

    getEquipmentTypeText(clockIn) {
      const  clockInVehicles  = clockIn.vehicles
      return !clockInVehicles || !clockInVehicles?.length ? translations.no : translations.yes
    },

    async openEquipmentDialog(clockIn) {
      clockIn.edit = true
      this.selectedInertClockIn = clockIn
      this.equipmentDialog = true
    },

    addEquipment() {
      const newEquipment = {
        hours: 0,
        vehicle: {
          code: '',
          label: ''
        }
      }
      if (!this.selectedInertClockIn.vehicles) this.$set(this.selectedInertClockIn, 'vehicles', [])
      this.selectedInertClockIn.vehicles.push(newEquipment)
    },

    deleteEquipment(index) {
      this.selectedInertClockIn.vehicles.splice(index, 1)
    },

    setClockInVehicle(val, index) {
      if (val) {
        const { code, label, id } = val
        this.selectedInertClockIn.vehicles[index].vehicle = { code, id, label }
      }
    },

    getProjectKeyCodeFromClockIn(clockIn) {
      return clockIn?.project?.keyCode || '-'
    },

    projectPartValue(clockIn) {
      return clockIn?.part?.keyCode || '-'
    },

    projectParts(clockIn) {
      return clockIn?.project?.parts
    },

    onProjectChange(clockIn) {
      clockIn.part = null
    }
  },

  computed: {
    ...mapState({
      clockInsDate: state => state.clockIns.clockInsDate
    }),

    icons: () => ({
      mdiChevronRight,
      mdiChevronLeft,
      mdiSquareEditOutline,
      mdiContentSaveOutline,
      mdiCancel,
      mdiClose,
      mdiPlus,
      mdiTrashCanOutline,
      mdiCheckCircleOutline,
      mdiCheck,
      mdiWeatherNight,
      mdiPencilCircleOutline,
      mdiPlusCircleOutline
    }),

    translations: () => translations,

    defaultProject() {
      return this.dataSource?.clockIns?.[0]?.sectorKeyCode || ''
    },

    computedClockIns() {
      if (!this.dataSource?.clockIns?.length) {
        return []
      } else if (this.dataSource?.clockIns.length) {
        return this.dataSource.clockIns.filter(clockIn => clockIn.id > 0)
      } else {
        return this.dataSource.clockIns
      }
    },

    hasLunchTime() {
      const hasTime = e => e.lunchTime !== null && e.lunchTime >= 0

      return this.computedClockIns.some(hasTime)
    },

    allClockInsAreConfirmed() {
      return this.computedClockIns.every(ci => ci.confirmed == true)
    },

    lunchBreakOptions() {
      return getLunchTimes()
    },

    resetAllowanceOnWeekend() {
      return getResetAllowanceOnWeekend()
    },

    showClockInTimeAlert() {
      return getShowClockInTimeAlert()
    },

    dateIsOnWeekend() {
      return this.selectedDate ? [0, 6].includes(this.momentToDate(this.selectedDate).getDay()) : false
    },

    missingTime() {
      // if no selectedClockInPair or dialogType or property is false do not persist
      if (Object.keys(this.selectedClockInPair).length <= 0 || this.dialogType === '' || !this.selectedClockInPair[this.dialogType]) return false
      return this.selectedClockInPair[this.dialogTypeHours] <= 0
    },

    disableSaveInertClockInItemsButton() {
      return this.newInertClockInItems.some(i => {
        return !i.sector 
          || (!i.hours || i.hours <= 0)
          || (getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activityNotes) 
          || (i.sector && !getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activity)
      })
    },
  }
}
</script>


<style>
.virt-grid-row {
  display: grid;
  grid-template-columns: 200px 10fr;
  position: relative;
}

.report-row .v-text-field .v-input__control .v-input__slot {
  min-height: 25px !important;
  display: flex !important;
  align-items: center !important;
}

.report-row .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  min-height: 25px !important;
}

.report-row .v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 13px;
}

.clockin-row {
  align-items: center;
  display: grid;
}

.clockin-mid-grid-row {
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  display: grid;
}

.clockin-row > * {
  align-items: center;
  text-align: -webkit-center;
  height: 50px;
  padding-top: 5px;
  padding-left: 1px;
}

.clockin-row:hover {
  background-color: #f4f8ff;
}

div.layoff > div.v-input {
  margin: 0;
}

.wb-w100 {
  word-wrap: break-word;
  width: 170px;
}

.f-12 {
  font-size: 12px;
}

.v-input.v-input--hide-details.v-input--dense.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 0;
}

.notes-text {
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  max-width: 120px;
}

/* part select styling  */
.report-row .part-select .v-input__control .v-input__slot {
  padding: 0px 8px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  flex-wrap: nowrap !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner {
  margin-top: 12px !important;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon .v-icon {
  font-size: 14px;
}

.report-row .part-select .v-input__control .v-input__slot .v-select__slot .v-input__append-inner .v-input__icon {
  height: 18px !important;
  width: 18px !important;
  max-width: 18px !important;
  min-width: 18px !important;
}

.security-type-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
}

.security-type-container .checkbox {
  margin: 0;
  padding: 0;
}

.textarea {
  margin: 0;
  padding: 10px 0;
}

/* end part select styling  */

.clock-in-notes .v-input__slot {
  max-height: 30px !important;
}

.clock-in-notes textarea {
  width: 120px !important;
  max-height: 30px !important;
  font-size: 10px !important;
  line-height: 16px !important;
  resize: none !important;
  border-radius: 5px;
  margin: 0 !important;
  padding: 0 !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
