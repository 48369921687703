<template>
  <div style="position: relative" class="py-4">
    <div class="pa-2" style="border: 1px DOTTED GREY; border-radius: 12px">
      <!-- Sector -->
      <div class="tom-clockin-new d-flex flex-column my-2">
        <span>{{ translations.selectYourSector }}</span>
        <v-menu :value="openSectorMenu" offset-y allow-overflow max-height="300px">
          <template v-slot:activator="{ on }">
            <v-text-field v-on="on"
                          v-model="sectorFilter"
                          @click="openSectorMenu = true"
                          @input="value => inputHandler(value, 'sector')"
                          @click:clear="clearSearchText('sector')"
                          clear-icon="mdi-close"
                          :label="translations.sector" @blur="textBlur"
                          outlined class="mt-2 mb-1" style="width: 100%" hide-details autocomplete="off" height="35px" dense clearable>
            </v-text-field>
          </template>
          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(sector, i) in sectorOptions.list" @click="changeSector(sector)" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ sector.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ sector.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="sectorOptions.next">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
        <p v-if="inertClockInItem.sector " class="pa-0 ma-0 grey--text" style="font-size: 14px">
          {{ inertClockInItem.sector.description }}
        </p>
      </div>
    
      <!-- Activity -->
      <div class="my-2">
        <span>{{ translations.selectYourActivity }}</span>
        <v-menu :value="openActivityMenu" offset-y allow-overflow max-height="300px">
          <template v-slot:activator="{ on }">
            <v-text-field v-on="on"
                          v-model="activityFilter"
                          @click="openActivityMenu = true"
                          @input="value => inputHandler(value, 'activity')"
                          @click:clear="clearSearchText('activity')"
                          :label="translations.activity"
                          outlined class="mt-2 mb-1" style="width: 100%" hide-details autocomplete="off" height="35px" dense clearable>
            </v-text-field>
          </template>
          <div style="max-height: 300px" class="scroll-bar">
            <v-list dense>
              <v-list-item v-for="(activity, i) in activityOptions.list" @click="changeActivity(activity)" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ activity.keyCode }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ activity.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="activityOptions.next">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <v-progress-circular v-intersect.quiet="onIntersectActivity" indeterminate color="main" size="24"></v-progress-circular>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
        <p v-if="inertClockInItem.activity " class="pa-0 ma-0 grey--text" style="font-size: 14px">
          {{ inertClockInItem.activity.description }}
        </p>
      </div>
    
      <!-- Activity Hours -->
      <div>
          <span>{{ translations.activityHours }}</span>
          <v-text-field style="text-transform: capitalize" v-model.number="inertClockInItem.hours" :label="translations.hours"
                      outlined class="my-2" height="35px" hide-details dense type="number" step="0.5">
          </v-text-field>
      </div>
    
      <!-- Activity Notes -->
      <div>
          <span>{{ translations.activityNotes }}</span>
          <v-textarea v-model="inertClockInItem.activityNotes" :label="translations.requiredFieldNote"
                      outlined counter="50" class="my-2" :height="65" dense>
          </v-textarea>
      </div>
    
      <!-- remove sector button -->
      <v-btn v-if="index !== 0" @click="removeInertClockInItem(index)" style="position: absolute; right: -10px; top: 0px; z-index: 2" x-small fab color="error">
        <v-icon class="white--text" small>mdi-close</v-icon>
      </v-btn>

      <!-- add sector button-->
      <v-btn v-if="isLast" @click="addInertClockInItem()" style="position: absolute; right: -10px; bottom: 0px; z-index: 2" x-small fab color="green">
        <v-icon class="white--text" small>mdi-plus</v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
import { translations } from '@/utils/common'
import { mdiTrashCanOutline, mdiPlus, mdiClose, mdiFilePdfOutline, mdiCheckCircle, mdiPlusCircle, mdiCloseCircle } from '@mdi/js'
import { cloneDeep } from 'lodash'

export default {
  name: 'InertClockInItem',

  props: {
    inertClockInItem: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    sectorOptions: {
      type: Object,
      default: () => {}
    },
    activityOptions: {
      type: Object,
      default: () => {}
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    source: {},
    openSectorMenu: false,
    openActivityMenu: false,
    forceUpdate: 0,
    forceUpdateLabel: 0,
    sectorFilter: null,
    activityFilter: null
  }),

  created() {
    this.getSelectedValues()
    this.source = cloneDeep(this.inertClockInItem)
  },

  methods: {
    textBlur() {
      setTimeout(() => {
          this.getSectorValue()
          this.openSectorMenu = false
          this.$emit('refetch', 'sector')
      }, 400)
    },

    getSelectedValues() {
      this.sectorFilter = this.getSectorValue()
      this.activityFilter = this.getActivityValue()
    },

    getSectorValue() {
      if (this.inertClockInItem?.sector?.keyCode) {
        return this.inertClockInItem?.sector?.keyCode
      } else return ''
    },

    getActivityValue() {
      if (this.inertClockInItem?.activity?.keyCode) {
        return this.inertClockInItem?.activity?.keyCode
      } else return ''
    },

    inputHandler(value, entity) {
      if (entity === 'sector') {
        this.sectorFilter = value
        this.sectorOptions.params.filter = value
        this.$emit('refetch', 'sector')
      } else {
        this.activityFilter = value
        this.activityOptions.params.filter = value
        this.$emit('refetch', 'activity')
      }
    },

    changeSector(value) {
      this.inertClockInItem.sector = value?.sector ? value.sector : value
      delete this.inertClockInItem.sector.__typename
      this.sectorFilter = value.keyCode
      this.openSectorMenu = false
    },

    changeActivity(value) {
      this.inertClockInItem.activity = value?.activity ? value.activity : value
      delete this.inertClockInItem.activity.__typename
      this.activityFilter = value.keyCode
      this.openActivityMenu = false
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more', 'sector')
      }
    },

    onIntersectActivity(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more', 'activity')
      }
    },

    addInertClockInItem() {
      this.$emit('add-item')
    },

    removeInertClockInItem() {
      this.$emit('remove-item', this.index)
    },

    clearSearchText(entity) {
      if (entity === 'sector') {
        this.sectorFilter = null
        this.inertClockInItem.sector = null
        this.openSectorMenu = false
      } else {
        this.activityFilter = null
        this.inertClockInItem.activity = null
        this.openActivityMenu = false
      }
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiClose,
      mdiPlus,
      mdiTrashCanOutline,
      mdiFilePdfOutline,
      mdiCheckCircle,
      mdiPlusCircle,
      mdiCloseCircle
    }),

    disabled() {
      return false
    },

    sectorValue() {
      return this.inertClockInItem?.sector
    },

    activityValue() {
      return this.inertClockInItem?.activity
    }
  },

  watch: {
    sectorValue() {
      this.sectorFilter = this.getSectorValue()
    },

    activityValue() {
      this.activityFilter = this.getActivityValue()
    }
  }
}
</script>
