<template>
  <v-dialog :value="isOpen" max-width="430px" @click:outside="$emit('clickOutside')" @keydown.esc="$emit('clickOutside')">
    <v-card class="pa-2">
      <v-card-title>
        <p class="ma-0" style="font-size: 1.25rem;">{{ translations.managingTimeCardFor }}:</p>
        <p class="ma-0 pl-1" style="font-size: 1.25rem;">
          {{ employeeName }}
        </p>
      </v-card-title>
      <v-card-text>
        <section class="mb-3">
          <v-radio-group class="ma-0 pa-0 mb-2" v-model="newInertClockInRadioGroupValue" hide-details row>
            <v-radio :label="translations.sector" :value="'sector'" @click="removeClockInRequestProject"></v-radio>
            <v-radio :label="translations.project" :value="'project'" @click="removeClockInRequestProject"></v-radio>
          </v-radio-group>
        </section>

        <!-- InertClockInItems -->
        <div v-if="newInertClockInRadioGroupValue === 'sector'">
          <div v-for="(item, index) in newClockInRequest.inertClockInItems" :key="index">
            <inert-clock-in-item
              :inertClockInItem="item"
              :index="index"
              :sectorOptions="sector"
              :activityOptions="activity"
              :isLast="isLast(index)"
              @fetch-more="value => $emit('fetch-more', value)"
              @refetch="value => $emit('refetch', value)"
              @add-item="addEmptyInertClockInItem()"
              @remove-item="value => removeInertClockInItem(value)"
            >
            </inert-clock-in-item>
          </div>
        </div>

        <!-- Project selection -->
        <div v-if="newInertClockInRadioGroupValue === 'project'" class="report-row mb-2">
          <label>{{ translations.project }}</label>
          <v-menu offset-y allow-overflow :close-on-click="true" :close-on-content-click="true">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                dense
                hide-details
                autocomplete="off"
                outlined
                height="35px"
                @click:clear="clearSearchHandler"
                :value="getProjectFilterValue()"
                clearable
                @input="value => inputProjectHandler(value)"
              ></v-text-field>
            </template>

            <div style="max-height: 300px" class="scroll-bar">
              <v-list dense>
                <template v-if="rowDialogProjectOptions.list.length > 0">
                  <v-list-item v-for="(project, index) in rowDialogProjectOptions.list" :key="`project-${project.id}_${index}`" @click="selectProjectHandler(project)">
                    <v-list-item-content>
                      <v-list-item-title>{{ project.keyCode }}</v-list-item-title>
                      <v-list-item-subtitle>{{ project.label }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="!rowDialogProjectOptions.loading && rowDialogProjectOptions.hasNext">
                    <div class="d-flex align-center justify-center" style="width: 100%">
                      <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" size="24"></v-progress-circular>
                    </div>
                  </v-list-item>
                </template>

                <template v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ translations.noResultsFound }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </div>
          </v-menu>
          <p v-if="newClockInRequest.inertClockInItems[0].project && newClockInRequest.inertClockInItems[0].project.label" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
            {{ newClockInRequest.inertClockInItems[0].project.label }}
          </p>
        </div>

        <!-- Part selection -->
        <div v-if="newInertClockInRadioGroupValue === 'project'" class="d-flex">
          <div class="report-row mb-2 mr-2">
            <label>{{ translations.part }}</label>
            <v-select v-model="newClockInRequest.inertClockInItems[0].part" :disabled="!dialogProjectParts" :items="dialogProjectParts" item-text="keyCode" clearable dense style="font-size: 14px" outlined append-icon="" height="35px" hide-details return-object>
              <template v-slot:item="data">
                <span>{{ `${data.item.keyCode} - ${data.item.description}` }}</span>
              </template>
            </v-select>
            <p v-if="newClockInRequest.inertClockInItems[0].part && newClockInRequest.inertClockInItems[0].part.description" class="pa-0 ma-0 pt-1 grey--text" style="font-size: 14px">
              {{ newClockInRequest.inertClockInItems[0].part.description }}
            </p>
          </div>
        </div>


        <!-- Lunch Break -->
        <div v-if="possibleQuestions.includes(4)" class="report-row mb-4">
          <label>{{ translations.howLongWasYourLunchBreak }}</label>
          <v-select class="mt-2 pa-0 mb-2" v-model="newClockInRequest.lunchTime" :items="lunchBreakDropdown"
                    item-text="text" item-value="value" dense outlined height="35px" hide-details
                    style="width: 200px">
          </v-select>
        </div>

        <!-- clock in/out times -->
        <div class="report-row">
          <label>{{ translations.type }} </label>
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.in" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockInTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>
        <div class="mt-2 mb-4 report-row">
          <div class="d-flex align-center">
            <v-text-field disabled :value="translations.out" class="mr-2" style="max-width: 100px; font-size: 14px" outlined height="35px" hide-details></v-text-field>
            <v-text-field v-model="newClockInRequest.clockOutTimestamp" v-mask="'##:##'" :rules="[rules.isTimeFormat]" style="max-width: 100px" outlined hide-details></v-text-field>
          </div>
        </div>

        <div v-if="showNextDayOutOnDialog" class="mb-2">
          <v-alert dense text type="warning" class="ma-0">{{ translations.shiftWillEndNextDay }}</v-alert>
        </div>

        <div class="mt-10 d-flex justify-end">
          <v-btn depressed color="main" :loading="newClockInLoader" :dark="!disableSubmitNewClockInClockOutButton" :disabled="disableSubmitNewClockInClockOutButton" @click="submitNewClockInClockOut">{{ translations.confirm }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { translations } from '@/utils/common'
import InertClockInItem from '@/components/InertClockInItem.vue'
import { getInertLunchTimesDefault, getInertSectorKeyCode, getLunchTimes } from '@/utils/api/config'

export default {
  name: 'NewInertClockInClockOutDialog',
  components: {
    InertClockInItem
  },

  props: {
    employeeName: {
      type: String
    },
    isOpen: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => {
      }
    },
    newClockInLoader: {
      type: Boolean
    },
    activity: {
      type: Object,
      default: () => {
      }
    },
    possibleQuestions: {
      type: Array,
      default: () => []
    },
    rowDialogProjectOptions: {
      type: Object,
      default: () => {
      }
    }
  },

  created() {
    this.newClockInRequest = this.getEmptyInertClockIn()
    this.lunchBreakDropdown = getLunchTimes()
  },

  data: () => ({
    newClockInRequest: null,
    rules: {
      isTimeFormat: value => {
        const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        return pattern.test(value)
      },
      isHour: value => {
        const pattern = /^(?!0$)\d+(?:[,.][05])?$/
        return pattern.test(value)
      }
    },
    lunchBreakDropdown: [],
    newInertClockInRadioGroupValue: 'sector'
  }),

  computed: {
    translations: () => translations,

    showNextDayOutOnDialog() {
      const clockInTime = Number(this.newClockInRequest.clockInTimeData.HH)
      const clockOutTime = Number(this.newClockInRequest.clockOutTimeData.HH)

      if (clockOutTime === 0) return false
      return clockInTime > clockOutTime
    },

    disableSubmitNewClockInClockOutButton() {
      if (this.newClockInRequest.inertClockInItems[0].project && this.rules.isTimeFormat(this.newClockInRequest.clockInTimestamp) &&
        (!this.newClockInRequest.clockOutTimestamp || this.rules.isTimeFormat(this.newClockInRequest.clockOutTimestamp))) return false

      if (!this.rules.isTimeFormat(this.newClockInRequest.clockInTimestamp) || (this.newClockInRequest.clockOutTimestamp && !this.rules.isTimeFormat(this.newClockInRequest.clockOutTimestamp))) return true

      if (this.newClockInRequest.lunchTime === null || this.newClockInRequest.lunchTime === 'undefined') return true

      const inertClockInItemsMissingFields = this.newClockInRequest.inertClockInItems.some(i => {
        return !i.sector
          || (!i.hours || i.hours <= 0)
          || (getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activityNotes)
          || (i.sector && !getInertSectorKeyCode().includes(i.sector.keyCode) && !i.activity)
      })

      if (inertClockInItemsMissingFields) return true

      return false
    },

    dialogProjectParts() {
      return this.newClockInRequest?.inertClockInItems[0]?.project?.parts
    }
  },

  methods: {
    clearSearchHandler() {
      this.newClockInRequest.inertClockInItems[0].project = null
    },

    getProjectFilterValue() {
      return this.newClockInRequest?.inertClockInItems[0]?.project?.keyCode ? this.newClockInRequest.inertClockInItems[0]?.project?.keyCode : ''
    },

    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        return this.$emit('fetch-more-projects', 'rowDialogProjectOptions')
      }
    },

    submitNewClockInClockOut() {
      const { inertClockInItems } = this.newClockInRequest
      const item = inertClockInItems[0]

      item.project = item.project ? {
        id: item.project.id,
        keyCode: item.project.keyCode,
        description: item.project.description
      } : null
      item.part = item.part ? {
        id: item.part.id,
        keyCode: item.part.keyCode,
        description: item.part.description
      } : null
      // this is needed so in the report rows, when project is selected, there will be no activity hours, otherwise it will be 0 by default
      item.hours = item.hours === 0 ? null : item.hours

      this.$emit('onSubmit', {
        newClockInRequest: this.newClockInRequest
      })
      this.newClockInRequest = this.getEmptyInertClockIn()
    },

    getEmptyInertClockIn() {
      return {
        employee: null,
        clockInTimeData: {
          HH: '00',
          mm: '00'
        },
        clockOutTimeData: {
          HH: '00',
          mm: '00'
        },
        referenceDate: null,
        lunchTime: getInertLunchTimesDefault(),
        inertClockInItems: [{
          sector: null,
          activity: null,
          hours: 0,
          activityNotes: null,
          project: null,
          part: null
        }]
      }
    },

    isLast(index) {
      return index === this.newClockInRequest.inertClockInItems.length - 1
    },

    addEmptyInertClockInItem() {
      this.sector.params.filter = null
      this.activity.params.filter = null
      this.$emit('refetch-all')
      this.newClockInRequest.inertClockInItems.push({
        sector: null,
        activity: null,
        hours: 0,
        activityNotes: null
      })
    },

    removeInertClockInItem(index) {
      this.newClockInRequest.inertClockInItems.splice(index, 1)
    },

    selectProjectHandler(project) {
      this.newClockInRequest.inertClockInItems[0].project = project
    },

    removeClockInRequestProject() {
      this.newClockInRequest = this.getEmptyInertClockIn()
    },

    inputProjectHandler(value) {
      this.$emit('set-project-list-filter', { value, property: 'rowDialogProjectOptions' })
    }
  }
}
</script>
